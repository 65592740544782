import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import './styles.scss';
import BigRoundButton from '../../BigRoundButton';
import lockIcon from '../../../assets/image/svg/lock.svg';
import GlobalContext from '../../../context/GlobalContext';
import { updateQueryParam } from '../../../utils/helperFn';

interface Props { }

const providersList = [
  { value: 'BT', label: 'BT' },
  { value: 'VIRGIN_MEDIA', label: 'Virgin Media' },
  { value: 'SKY', label: 'Sky' },
  { value: 'TALKTALK', label: 'TalkTalk' },
  { value: 'VODAFONE', label: 'Vodafone' },
  { value: 'OTHER', label: 'Other' },
  { value: 'I_HAVE_NO_ACTIVE_SERVICE', label: 'I have no active connection' }
];

const CurrentProviderForm: React.FC<Props> = () => {
  const gContext: any = useContext(GlobalContext);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
    updateQueryParam('step', '1');
  }, []);

  const setProvider = (provider: string) => {
    const registrationData = gContext.goGetRegistrationData();
    if (registrationData) {
      gContext.goSetRegistrationData({
        ...registrationData,
        currentProvider: provider,
        registerStep: provider === 'I_HAVE_NO_ACTIVE_SERVICE' ? 3 : 2
      });
    }
  };

  const handleContinue = () => {
    const registrationData = gContext.goGetRegistrationData();
    gContext.goSetRegistrationData({
      ...registrationData,
      registerStep: gContext.goGetRegistrationData().currentProvider === 'I_HAVE_NO_ACTIVE_SERVICE' ? 3 : 2
    });
  };

  return (
    <>
      <Row className="justify-content-center pt-1 pb-4 ml-5 mr-5">
        <Col sm={12} md={4}>
          {/* Provider List */}
          <Row>
            {providersList.map((provider, i) => (
              <Col
                sm={12}
                key={i}
                onClick={() => setProvider(provider.value)}
                className={`providerButton text-center ${i < providersList.length ? 'mb-3' : ''} ${gContext?.goGetRegistrationData()?.currentProvider === provider.value ? 'selected' : ''}`}
              >
                <span>{provider.label}</span>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col sm={12} md={4}>
          <BigRoundButton title="Continue" onClick={handleContinue} />
        </Col>
      </Row>

      {/* SSL Lock Icon */}
      <Row className="justify-content-center">
        <Col sm={12} md={4} className="text-center mt-3">
          <img src={lockIcon} alt="SSL Secure" className="img-fluid" style={{ maxWidth: '14px', color: 'black' }} />{' '}
          <span style={{ color: 'black', fontSize: '0.8em', marginLeft: 3 }}>SSL Secure</span>
        </Col>
      </Row>
    </>
  );
};

export default CurrentProviderForm;